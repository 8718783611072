<template>
<div :class="'apps dirr ' + $store.state.direction" v-if="$i18n.locale=='ar'">
    <v-container>
        <v-row no-gutters>
            <v-col cols="12" sm="6" xs="12" md="3" lg="3" class="appsMenu" v-if="apps_skeleton">
                <v-skeleton-loader  class="mx-auto" max-width="300" type="table-cell@5"></v-skeleton-loader>
                <v-skeleton-loader  class="mx-auto" max-width="300" type="table-cell@5"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="3" lg="3" class="appsMenu" v-else>
                <div class="title">
                    <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                    <p class="tl">تطبيقات الجوال</p>
                </div>
                <div class="appsList">
                    <div class="app" :id="i.id" v-for="i in appres" :key="i" @click.prevent="DefApp(i.id)">
                        <img :src="resUrl+'img/apps/'+i.pic" :alt="i.title">
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="5" lg="6" class="appDesc" v-if="loading">
                <v-skeleton-loader  class="mx-auto" max-width="100%" type="paragraph, sentences, paragraph, sentences, actions"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="5" lg="6" class="appDesc" v-else>
                <p class="tl">{{DefAppres.title}}</p>
                <p class="tx">{{DefAppres.description}}</p>
                <div class="stores">
                    <a :href="DefAppres.google" target="_blank" class="playStore" v-if="DefAppres.google">
                        <img :src="$vuetify.theme.dark ? '/assets/img/apps/google_play_dark.svg' : '/assets/img/apps/google_play.svg'" />
                        
                    </a>
                    <span></span>
                    <a :href="DefAppres.apple" target="_blank" class="appStore" v-if="DefAppres.apple">
                        <img :src="$vuetify.theme.dark ? '/assets/img/apps/app_store_dark.svg' : '/assets/img/apps/app_store.svg'" />
                    </a>
                </div>
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="3" lg="3" class="appImg d-none d-sm-none d-md-flex" v-if="loading">
                <v-skeleton-loader class="mx-auto" width="350" min-width="350" max-width="350" type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="3" lg="3" class="appImg d-none d-sm-none d-md-flex" v-else>
                <div class="img_con">
                    <div class="cover"></div>
                    <img :src="resUrl+'img/apps/'+DefAppres.sec_pic" alt="">
                </div>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>


<script>

export default {
    name: 'Apps',
    data() {
        return {
            appres:[],
            DefAppres:[],
            apps_skeleton:true,
            loading: true
        }
    },
    components: {

    },
    directives: {

    },
    computed: {
    },
    mounted() {
        
    },
    created () {
        this.initialize();
        this. DefApp(1);
    },
    methods:{
        initialize() {
            this.$axios.get("apps/all", {
            })
            .then(res => {
                this.apps_skeleton = false;
                this.appres = res.data.Data;
                
            })
        },
        DefApp(i) {
            this.$axios.get("apps/ById/"+i, {
            })
            .then(res => {
                this.loading = false;
                this.DefAppres = res.data.Data;
            })
        },
    }
}
</script> 